export const INITIAL_CAR_FILTERS = 'INITIAL_CAR_FILTERS';
export const UPDATE_CARS = 'UPDATE_CARS';
export const RESET_CARS = 'RESET_CARS';
export const UPDATE_CARS_TIMESTAMP = 'UPDATE_CARS_TIMESTAMP';
export const UPDATE_TOTAL_COUNT = 'UPDATE_TOTAL_COUNT';
export const UPDATE_SITES = 'UPDATE_SITES';
export const UPDATE_ARTICLES = 'UPDATE_ARTICLES';
export const GET_CARS = 'GET_CARS';
export const GET_CARS_TIMESTAMP = 'GET_CARS_TIMESTAMP';
export const GET_TOTAL_COUNT = 'GET_TOTAL_COUNT';
export const GET_SITES = 'GET_SITES';
export const GET_ARTICLES = 'GET_ARTICLES';
export const UPDATE_CAR_FILTERS = 'UPDATE_CAR_FILTERS';
export const UPDATE_CURRENT_QUERY = 'UPDATE_CURRENT_QUERY';
export const UPDATE_FILTER_CHOICES = 'GET_CAR_FILTERS';
export const UPDATE_FILTER_CHOICES_RANGE = 'UPDATE_FILTER_CHOICES_RANGE';
export const UPDATE_QUERY_PARAMS_URL = 'UPDATE_QUERY_PARAMS_URL';
export const CLEAR_QUERY_PARAMS_URL = 'CLEAR_QUERY_PARAMS_URL';
export const CHECK_QUERY_PARAMS = 'CHECK_QUERY_PARAMS';
export const GET_QUERY_PARAMS = 'GET_QUERY_PARAMS';

export const INITIAL_ARTICLE_FILTERS = 'INITIAL_ARTICLE_FILTERS';
export const INITIAL_SITE_FILTERS = 'INITIAL_SITE_FILTERS';
export const UPDATE_SITE_FILTERS = 'UPDATE_SITE_FILTERS';
export const UPDATE_ARTICLE_FILTERS = 'UPDATE_ARTICLE_FILTERS';
export const UPDATE_TIMES_SEARCHED_FILTERS = 'UPDATE_TIMES_SEARCHED_FILTERS';
export const UPDATE_CURRENT_CAR = 'UPDATE_CURRENT_CAR';

export const UPDATE_ALL_DYNAMIC_FILTERS = 'UPDATE_ALL_DYNAMIC_FILTERS';

export const RANGE_FILTERS_MIN_MAX_VALUES = 'RANGE_FILTERS_MIN_MAX_VALUES';
export const GET_RANGE_FILTERS_MIN_MAX_VALUES = 'GET_RANGE_FILTERS_MIN_MAX_VALUES';
